import React from "react";
import { Controller } from "react-hook-form";
import BootstrapFormField from "../../../react-hook-form/BootstrapFormField";
import { NumericFormat } from "react-number-format";
import { phone, requiredNotBlank } from "../../../form-validation";

function RegistrantPhone({ control, errors, ...rest }) {
  return (
    <Controller
      name="phone"
      control={control}
      rules={{
        validate: {
          required: requiredNotBlank("Required"),
          phone: phone(),
        },
      }}
      render={({ ref, ...props }) => (
        <BootstrapFormField
          format="(###) ### - ####"
          error={errors.phone}
          label="Phone"
          id="phone"
          as={NumericFormat}
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrantPhone;
