import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import classes from "./EventDeclineInvitationSuccessPage.module.css";
import SempraButton from "../../component/SempraButton/SempraButton";

function EventDeclineInvitationSuccessPage({ event }) {
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(`/${window.location.search}`);
  }

  return (
    <Container>
      <h1 className={classes.heading}>Thank you for your response!</h1>
      <p>
        If you change your mind, click{" "}
        <Link to={`/events/${event.id}/register${window.location.search}`}>
          here
        </Link>{" "}
        to register for <strong>{event.name}</strong>.
      </p>
      <SempraButton type="button" outlined onClick={onBackButtonClick}>
        Back to Events
      </SempraButton>
    </Container>
  );
}

export default EventDeclineInvitationSuccessPage;
