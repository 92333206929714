import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import classes from "./EventRegistrationSuccessPage.module.css";
import RegistrationSuccessActionsGrid from "../../component/RegistrationSuccessActionsGrid/RegistrationSuccessActionsGrid";
import SempraButton from "../../component/SempraButton/SempraButton";

function EventRegistrationSuccessPage({ event, initialRegistration = false }) {
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(`/${window.location.search}`);
  }

  const headerText = initialRegistration
    ? "Thank you for registering!"
    : "Your registration has been changed!";

  const eventHail = initialRegistration
    ? "We look forward to seeing you at "
    : "Thank you for updating your registation for ";

  return (
    <div>
      <Container>
        <h1 className={classes.heading}>{headerText}</h1>
        <p>
          {eventHail}
          <strong>{event.name}</strong>.
          <br />
          If you need to cancel your registration at anytime, please let us know
          so we can reopen your spot.
        </p>
      </Container>
      <RegistrationSuccessActionsGrid
        className={classes.actionGrid}
        event={event}
        registration={event.registration}
      />
      <Container>
        <SempraButton type="button" outlined onClick={onBackButtonClick}>
          Back to Events
        </SempraButton>
      </Container>
    </div>
  );
}

export default EventRegistrationSuccessPage;
