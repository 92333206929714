import { firestore } from "../../firebase";
import { convertFromRaw, EditorState } from "draft-js";
import { compose, map, firestoreDocumentQuery } from "../../resource";
import { registrationStatus } from "../../event";
import  queryString from "query-string";
import { useEventRegistrant } from "./useEventRegistrant";
import { comparing, dateComparator } from "../../sorting";

function eventDocumentToEvent(document) {
  const data = document.data();
  return {
    id: document.id,
    type: data.type,
    name: data.name,
    description: data.description
      ? EditorState.createWithContent(convertFromRaw(data.description))
      : EditorState.createEmpty(),
    status: data.status,
    startsAt: data.startsAt?.toDate(),
    endsAt: data.endsAt?.toDate(),
    registrationDeadline: data.registrationDeadline?.toDate(),
    imageUrl: data.image?.url,
    contact: data.contact,
    isVirtualEvent: data?.isVirtualEvent,
    virtualEventLink: data?.virtualEventLink,
    location: {
      ...(data.location || {}),
      additionalDirections: data.location?.additionalDirections
        ? EditorState.createWithContent(
            convertFromRaw(data.location.additionalDirections)
          )
        : EditorState.createEmpty(),
    },
    meals: data.meals
      ?.map((meal) => ({
        ...meal,
        startTime: meal.startTime?.toDate() || meal.startsAt?.toDate(),
        endTime: meal.endTime?.toDate() || meal.endsAt?.toDate(),
      }))
      .sort(comparing(dateComparator, (x) => x.startTime)),
    workshops: data.workshops?.map((workshop) => ({
      ...workshop,
      startTime: workshop.startTime?.toDate(),
      endTime: workshop.endTime?.toDate(),
    })),
    groups: data.groups || [],
    reservationCountBySubgroupId: data.reservationCountBySubgroupId || {},
    documents: data.documents,
    company: data.company,
    askForCompanyContact: data.askForCompanyContact,
    askIfFirstEvent: data.askIfFirstEvent,
    askIfRepresentedByUnion: data.askIfRepresentedByUnion,
    askForReleaseWaiver: data.askForReleaseWaiver,
    full: data.full,
  };
}

// const useReadonlyEvent = firestoreDocumentQuery((id) => firebaseReference.firestore().doc(`readonly_events/${id}`))

const useReadonlyEvent = firestoreDocumentQuery((id) => firestore.collection('`readonly_events').doc(id))
  .map(eventDocumentToEvent)
  .mapError((error) => {
    console.error("Failed to get event", error);
    return error;
  })
  .build();

export const useEvent = (user, eventId) => {
  const { registrationId } = queryString.parse(window.location.search || "") || {};
  return map(
    compose(
      useReadonlyEvent(eventId),
      useEventRegistrant(user, eventId, registrationId)
    ),
    ([event, registration = {}]) => ({
      ...event,
      registration,
      // support older data lookups
      registrant: registration,
      isRegistered:
        registration && ["registered", "waiting"].includes(registration.status),
    })
  );
};
